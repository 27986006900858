import { setAttributes } from './services/ui-utils';
import { ApplePayStore } from './ApplePayStore';
import {isApplePayAvailable} from "./services";

export class ApplePay {

    private button: HTMLElement

    constructor(private containerElement: HTMLElement,
                private store: ApplePayStore) {

        const isReadyToPay = isApplePayAvailable()
        store.onReadyToPayChange(isReadyToPay)

        // init UI related stuff
        if (isReadyToPay) {
            this.button = document.createElement('div')

            setAttributes(this.button, {
                id: 'dna-apple-pay-btn',
                style: 'width: 100%; height: 100%; min-height: 40px; display: block; cursor:pointer; -webkit-appearance: -apple-pay-button;',
                lang: 'en'
            })

            this.button.onclick = this.onClick
            this.containerElement.appendChild(this.button)
            this.store.onLoad()
        }
    }

    onClick = () => {
        this.store.onClick()
    }
}
