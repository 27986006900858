import {ApiResponse, ContentType, post} from "@/modules/back-connector";


export function postWithToken<T>(url: string, token, data?: ContentType, headers?: [string,string][]): Promise<ApiResponse<T>> {
    return post(url, data, [
        ['Authorization', 'Bearer ' + token],
        ...(headers ? headers : [])
    ])
}

export function validateApplePaySession (apiUrl, token, data: {url, displayName, merchantId?, domainName?}) {
    return postWithToken(`${ apiUrl }/v1/applepay/session`, token, data)
}
