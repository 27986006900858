import {PaymentDataV2} from "@/modules/payment-core";
import {ApplePayStore} from "./ApplePayStore";
import {ApplePay} from "./ApplePay";
import {Events} from "./models/Events";

export const create = async (containerElement: HTMLElement,
                        paymentData: PaymentDataV2,
                        events: Events,
                        token: string, payload?) => {
    const store = new ApplePayStore(paymentData, events, {token, isTest: __IS_DEV__}, payload)
    await store.init()
    return new ApplePay(containerElement, store)
}

if (!window.DNAPayments) {
    window.DNAPayments = {}
}

window.DNAPayments.ApplePayComponent = {
    create
}
