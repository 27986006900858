import {
    FAILED_TO_AUTHORIZE_APPLE_PAY_PAYMENT,
    FAILED_TO_INITIALIZE_APPLE_PAY_BUTTON,
    FAILED_TO_PROCESS_APPLE_PAY_PAYMENT,
    FAILED_TO_UPDATE_SHIPPING_METHOD,
    FAILED_TO_VALIDATE_APPLE_PAY_SESSION,
    NO_CARD_NETWORK_CONFIGURED
} from "./ErrorCode"

export const Errors = {
    failedToInitializeApplePay: {
        code: FAILED_TO_INITIALIZE_APPLE_PAY_BUTTON,
        message: 'Failed to initialize the Apple Pay button'
    },
    noCardNetwork: {
        code: NO_CARD_NETWORK_CONFIGURED,
        message: 'There are no card networks configured for this terminal'
    },
    failedToValidateApplePaySession: {
        code: FAILED_TO_VALIDATE_APPLE_PAY_SESSION,
        message: 'Could not validate Apple Pay session'
    },
    failedToAuthorizeApplePayPayment: {
        code: FAILED_TO_AUTHORIZE_APPLE_PAY_PAYMENT,
        message: 'Failed to authorize the Apple Pay payment'
    },
    failedToProcessApplePayPayment: {
        code: FAILED_TO_PROCESS_APPLE_PAY_PAYMENT,
        message: 'Failed to process the Apple Pay payment'
    },
    failedToUpdateShippingMethod: {
        code: FAILED_TO_UPDATE_SHIPPING_METHOD,
        message: 'Failed to update the shipping method'
    },
}
