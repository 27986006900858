import {Client, PaymentDataV2} from '@/modules/payment-core'
import {CryptoCardData} from "@/modules/payment-core/models/CardPayment/CryptoCardData";
import {getAPIUrl} from '@/modules/payment-core/services/getters';
import {ApplePaySession, extractAllowedCardNetworks, startApplePaySession, validateApplePaySession} from './services'
import {Configuration} from "./models/Configuration";
import {Events} from "./models/Events";
import {Errors} from "./constants/Errors";

export class ApplePayStore {

    private applePaySession: any

    private client: Client

    constructor(private paymentData: PaymentDataV2, private events: Events, private configuration: Configuration, private payload?) {}

    init = async () => {
        this.client = new Client(this.configuration)
        await this.client.init()
    }

    public onLoad = () => {
        this.events?.onLoad && this.events.onLoad()
    }

    public onClick = () => {
        this.events?.onClick && this.events.onClick()
        this.startApplePaySession()
    }

    public onReadyToPayChange = (isReadyToPay: boolean) => {
        const cardNetworks = this.allowedCardNetworks
        if (!isReadyToPay) {
            this.onError(Errors.failedToInitializeApplePay)
        } else if (!cardNetworks || cardNetworks.length === 0) {
            this.onError(Errors.noCardNetwork)
        }
    }

    public get merchantName() {
        return this.paymentData?.requestorDetails?.merchantName || this.client?.getConfiguration()?.merchantName || this.paymentData?.paymentSettings?.terminalId || ''
    }

    public onError = (error: {code: number, message: string, additionalInfo?: any}) => {
        this.events?.onError && this.events?.onError(error)
    }

    private handleApplePaySessionEvents = (applePaySession: any) => {
        applePaySession.onvalidatemerchant = (event: any) => {

            let merchantId = this.client?.getConfiguration()?.merchantId
            let domainName = window.location.hostname
            if (document.referrer) {
                domainName = new URL(document.referrer)?.hostname
            }

            if (['pay.dnapayments.com', 'test-pay.dnapayments.com', 'dev-pay.dnapayments.com'].includes(domainName?.toLowerCase())) {
                domainName = undefined
                merchantId = undefined
            }

            validateApplePaySession(
                getAPIUrl(this.configuration.isTest),
                this.configuration.token,
                {
                    url: event.validationURL,
                    displayName: this.merchantName,
                    merchantId,
                    domainName
                })
                .then(result => {
                    const decodedData = window.atob(result.result as string)
                    const parsedData = JSON.parse(decodedData)
                    if (parsedData?.statusMessage) {
                        this.onError({...Errors.failedToValidateApplePaySession, additionalInfo: parsedData })
                    } else {
                        applePaySession.completeMerchantValidation(parsedData)
                    }
                })
                .catch(e => {
                    applePaySession.completePayment(ApplePaySession.STATUS_FAILURE)
                    this.onError({...Errors.failedToValidateApplePaySession, additionalInfo: e})
                })
        }

        applePaySession.onpaymentauthorized = (event: any) => {
            console.log('onpaymentauthorized', event.payment)
            const cardData: CryptoCardData = {
                cardHolderName: `${this.paymentData?.customerDetails?.firstName || ''} ${ this.paymentData?.customerDetails?.lastName || ''}`.trim(),
                expiryDate: null,
                cardNumber: null,
                cvv: null
            }
            this.paymentData.paymentMethod = 'applepay'

            // DO THE PAYMENT HERE
            this.client.cryptoPayV2(this.paymentData, cardData, event.payment).
                then(result => {
                    if (result && result.result?.success) {
                        this.applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS)
                        this.events?.onPaymentSuccess && this.events?.onPaymentSuccess(result.result)
                    } else {
                        applePaySession.completePayment(ApplePaySession.STATUS_FAILURE)
                        this.onError(Errors.failedToAuthorizeApplePayPayment)
                    }
                })
                .catch(e => {
                    applePaySession.completePayment(ApplePaySession.STATUS_FAILURE)
                    this.onError({...Errors.failedToProcessApplePayPayment, additionalInfo: e})
                })
        }

        applePaySession.oncancel = (args) => {
            console.log('oncancel',  JSON.stringify(args, null, 2))
            this.events?.onCancel && this.events?.onCancel(args)
        }

        applePaySession.onshippingcontactselected = (event) => {
            console.log('onshippingcontactselected', event.shippingContact)

            if (this.events?.onShippingContactSelected) {
                const promise = new Promise((resolve, reject) => {
                    this.events?.onShippingContactSelected(resolve, reject, event)
                });

                // Handle the promise result
                promise
                    .then((data: any) => {
                        console.log("Shipping Contact Update From Merchant:", data);
                        let update = {
                            ...data,
                            newTotal: {
                                label: this.merchantName,
                                type: 'final',
                                amount: this.paymentData?.amount,
                                ...data?.newTotal
                            },
                            newLineItems: data.newLineItems || this.paymentData?.orderLines?.map(line => ({
                                label: line.name,
                                amount: line.totalAmount
                            })),
                            newShippingMethods: data.newShippingMethods || this.payload?.shippingMethods
                        }
                        this.paymentData.amount = update.newTotal.amount
                        applePaySession.completeShippingContactSelection(update);
                    })
                    .catch(error => {
                        console.error("Shipping Contact Update From Merchant Failed:", error);
                        const update = {
                            errors: [
                                {
                                    code: "shippingContactUpdateFailed",
                                    contactField: "postalAddress",
                                    message: "Failed to update shipping address"
                                }
                            ]
                        }
                        applePaySession.completeShippingContactSelection(update);
                    });
            }
        }

        applePaySession.onshippingmethodselected = (event) => {
            console.log('onshippingmethodselected', event.shippingMethod)
            const promise = new Promise((resolve, reject) => {
                this.events?.onShippingMethodSelected(resolve, reject, event)
            });

            // Handle the promise result
            promise
                .then((data: any) => {
                    console.log("Shipping Method Update From Merchant:", data);
                    let update = {
                        ...data,
                        newTotal: {
                            label: this.merchantName,
                            type: 'final',
                            amount: this.paymentData?.amount,
                            ...data?.newTotal
                        }
                    }
                    this.paymentData.amount = update.newTotal.amount
                    applePaySession.completeShippingMethodSelection(update);
                })
                .catch(e => {
                    console.error("Shipping Method Update From Merchant Failed:", e);
                    applePaySession.completePayment(ApplePaySession.STATUS_FAILURE);
                    this.onError({...Errors.failedToUpdateShippingMethod, additionalInfo: e})
                });
        }
    }

    public startApplePaySession = async () => {
        console.log('Config', this.config)
        this.applePaySession = startApplePaySession(this.config)
        const callback = this.events?.onBeforeProcessPayment || this.events?.onBeforeProccessPayment

        if (callback) {
            const { paymentData, token } = (await callback() || {})
            if (paymentData) {
                this.paymentData = paymentData
            }
            if (token) {
                this.client.setToken(token)
            }
        }

        this.handleApplePaySessionEvents(this.applePaySession)
        this.applePaySession.begin()
    }

    public get config () {
        console.log('Order lines: ', this.paymentData?.orderLines)
        const lineItems = this.paymentData?.orderLines?.map(line => ({
            label: line.name,
            amount: line.totalAmount
        })) || undefined
        console.log('Line items: ', lineItems)

        return {
            currencyCode: this.paymentData?.currency || 'GBP',
            countryCode: this.paymentData?.customerDetails?.billingAddress?.country || 'GB',
            merchantCapabilities: [
                'supports3DS',
            ],
            supportedNetworks: this.allowedCardNetworks,
            lineItems,
            total: {
                label: this.merchantName,
                amount: this.paymentData?.amount,
                type: 'final'
            },
            requiredBillingContactFields: this.payload?.requiredBillingContactFields, //['postalAddress'],
            requiredShippingContactFields: this.payload?.requiredShippingContactFields, //['postalAddress', 'name', 'email', 'phone']
            ...this.payload
        }
    }

    public get allowedCardNetworks () {
        // TODO: ONPAY-1023
        // return extractAllowedCardNetworks(this.client?.getConfiguration()?.paymentMethodsSettings?.bankCard?.acceptedCardSchemes)
        return ['MASTERCARD', 'VISA']
    }
}
